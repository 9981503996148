<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="sendnotification"
      :item_no="item_No ? item_No : 0"
    />
    <v-container>
      <v-card class="rounded-lg">
        <v-container>
          <v-toolbar class="elevation-0">
            <v-tabs class="tab-mobileres" color="secondary">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                tag
                class="text-capitalize"
                style="font-size: 16px; width: 130px"
                @click="
                  $store.commit('changeTab', 'tab-1'),
                    clear(),
                    changeTabTitle('article')
                "
              >
                <v-icon style="font-size: 30px">mdi-file-document</v-icon>
                {{ $t("article") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 16px; width: 150px"
                @click="
                  $store.commit('changeTab', 'tab-2'),
                    clear(),
                    changeTabTitle('sound')
                "
              >
                <v-icon style="font-size: 30px">mdi-volume-high</v-icon>
                {{ $t("sound") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 16px; width: 130px"
                @click="
                  $store.commit('changeTab', 'tab-3'),
                    clear(),
                    changeTabTitle('video')
                "
              >
                <v-img
                  v-if="$store.getters.tabs == 'tab-3'"
                  src="@/assets/images/video_img.svg"
                  width="28"
                  height="28"
                  class="mr-0"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/images/video_img_black.svg"
                  width="28"
                  height="28"
                  class="mr-0"
                ></v-img>
                {{ $t("video") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 16px"
                @click="
                  $store.commit('changeTab', 'tab-4'),
                    clear(),
                    changeTabTitle('lessonlearn')
                "
              >
                <v-img
                  v-if="$store.getters.tabs == 'tab-4'"
                  src="@/assets/images/lessonLearn_img.svg"
                  width="23"
                  height="23"
                  class="mx-auto mr-1"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/images/book_img.svg"
                  width="23"
                  height="23"
                  class="mx-auto mr-1"
                ></v-img>
                {{ $t("lessonlearn") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 16px"
                @click="
                  $store.commit('changeTab', 'tab-5'),
                    clear(),
                    changeTabTitle('course')
                "
              >
                <v-icon style="font-size: 30px">mdi-play-circle</v-icon>
                {{ $t("course") }}
              </v-tab>

              <v-tab
                class="text-capitalize"
                style="font-size: 16px"
                @click="
                  $store.commit('changeTab', 'tab-6'),
                    clear(),
                    changeTabTitle('user')
                "
              >
                <v-icon style="font-size: 30px">mdi-account</v-icon>
                {{ $t("user") }}
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-btn
           v-if="tabs !== 'tab-6'"
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #a6cc39;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>

          <div
            class="mt-5"
            v-if="this.$store.getters.tabs != 'tab-6' && showFilterTools"
          >
            <v-row>
              <v-col style="margin-top: 14px" lg="4">
                <v-row>
                  <v-col>
                    <label
                      for="startdate"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("startdate") }}</label
                    >
                    <v-menu
                      v-model="frommenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startdatefrom"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          outlined
                          dense
                          placeholder="-- Please select --"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedDates"
                        v-model="startdatefrom"
                        @input="StartDateFromInput()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-divider inset style="border-color: #424242"></v-divider>
                  <v-col>
                    <label
                      for="to"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("to") }}</label
                    >
                    <v-menu
                      v-model="tomenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startdateto"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          outlined
                          dense
                          readonly
                          placeholder="-- Please select --"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedDatesTo"
                        v-model="startdateto"
                        @input="StartDateToInput"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>

              <v-col style="margin-top: 14px" lg="4">
                <v-row>
                  <v-col>
                    <label
                      for="enddate"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("enddate") }}</label
                    >
                    <v-menu
                      v-model="endmenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="enddatefrom"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          outlined
                          dense
                          placeholder="-- Please select --"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedEndDates"
                        v-model="enddatefrom"
                        @input="EndDateFromInput()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-divider inset style="border-color: #424242"></v-divider>
                  <v-col>
                    <label
                      for="to"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("to") }}</label
                    >
                    <v-menu
                      v-model="endmenuto"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="enddateto"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          outlined
                          dense
                          readonly
                          placeholder="-- Please select --"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedEndDatesTo"
                        v-model="enddateto"
                        @input="EndDateToInput()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>

              <v-col style="margin-top: 14px" lg="4">
                <v-row v-if="this.$store.getters.tabs != 'tab-5'">
                  <v-col>
                    <label
                      for="expireddate"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("expireddate") }}</label
                    >
                    <v-menu
                      v-model="expireddatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="expireddatefrom"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          outlined
                          dense
                          placeholder="-- Please select --"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="expireddatefrom"
                        @input="ExpireDateFromInput()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-divider inset style="border-color: #424242"></v-divider>
                  <v-col>
                    <label
                      for="to"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("to") }}</label
                    >
                    <v-menu
                      v-model="expireddatemenuto"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="expireddateto"
                          prepend-inner-icon="mdi-calendar-blank-outline"
                          outlined
                          dense
                          readonly
                          placeholder="-- Please select --"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :allowed-dates="allowedExpDate"
                        v-model="expireddateto"
                        @input="ExpireDateToInput()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              v-if="
                this.$store.getters.tabs != 'tab-4' &&
                this.$store.getters.tabs != 'tab-5'
              "
            >
              <v-col cols="12" lg="10">
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pre-fix">
                    <label
                      for="category"
                      style="
                        font-size: 16px;
                        color: #424242;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("category") }}</label
                    >
                    <v-select
                      v-model="category"
                      :items="Categoryitems"
                      item-text="topicName"
                      item-value="id"
                      hide-no-data
                      @change="(event) => SelectCategory(event)"
                      placeholder="-- Please select --"
                      hide-details
                      solo
                      dense
                      clearable
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="getSelectAll">
                          <v-list-item-action>
                            <v-icon
                              :color="category.length > 0 ? '#a6cc39' : ''"
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.topicName }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ category.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pre-fix">
                    <label
                      for="title"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("title") }}</label
                    >
                    <v-select
                      v-model="title"
                      :items="Titleitems"
                      hide-no-data
                      hide-details
                      solo
                      placeholder="-- Please select --"
                      dense
                      item-text="title"
                      item-value="id"
                      clearable
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="SelectAllTitle">
                          <v-list-item-action>
                            <v-icon :color="title.length > 0 ? '#a6cc39' : ''">
                              {{ Titleicon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.title }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ title.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    xl="4"
                    lg="4"
                    style="padding-top: 2.2rem"
                  >
                    <v-btn
                      text
                      class="text-capitalize"
                      height="40"
                      style="
                        border: 1px solid #c0c0c0;
                        border-radius: 8px;
                        color: #424242;
                        font-size: 16px;
                      "
                      @click="clear()"
                      >{{ $t("clearall") }}</v-btn
                    >
                    <v-btn
                      width="120"
                      height="40"
                      class="text-capitalize btn_hover_effect ml-4"
                      style="
                        color: #ffff;
                        border: 1px solid #a6cc39;
                        border-radius: 8px;
                        font-size: 16px;
                      "
                      color="#A6CC39"
                      @click="Search()"
                      >{{ $t("search") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              v-if="
                this.$store.getters.tabs == 'tab-4' &&
                this.$store.getters.tabs != 'tab-5'
              "
            >
              <v-col cols="12" lg="10">
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pre-fix">
                    <label
                      for="title"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("title") }}</label
                    >
                    <v-select
                      v-model="title"
                      :items="Titleitems"
                      hide-no-data
                      hide-details
                      solo
                      placeholder="-- Please select --"
                      dense
                      item-text="title"
                      item-value="id"
                      clearable
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="SelectAllTitle">
                          <v-list-item-action>
                            <v-icon :color="title.length > 0 ? '#a6cc39' : ''">
                              {{ Titleicon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.title }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ title.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    xl="4"
                    lg="4"
                    style="padding-top: 2.2rem"
                  >
                    <v-btn
                      text
                      class="text-capitalize"
                      height="40"
                      style="
                        border: 1px solid #c0c0c0;
                        border-radius: 8px;
                        color: #424242;
                        font-size: 16px;
                      "
                      @click="clear()"
                      >{{ $t("clearall") }}</v-btn
                    >
                    <v-btn
                      width="120"
                      height="40"
                      class="text-capitalize btn_hover_effect ml-4"
                      style="
                        color: #ffff;
                        border: 1px solid #a6cc39;
                        border-radius: 8px;
                        font-size: 16px;
                      "
                      color="#A6CC39"
                      @click="Search()"
                      >{{ $t("search") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="this.$store.getters.tabs == 'tab-5'">
              <v-col cols="12" lg="10">
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pre-fix">
                    <label
                      for="course"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("course") }}</label
                    >
                    <v-select
                      :items="Categoryitems"
                      hide-no-data
                      v-model="category"
                      hide-details
                      item-text="courseName"
                      item-value="courseID"
                      solo
                      dense
                      @change="(event) => SelectCourse(event)"
                      multiple
                      clearable
                      placeholder="-- Please select --"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="getSelectAll2">
                          <v-list-item-action>
                            <v-icon
                              :color="category.length > 0 ? '#a6cc39' : ''"
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.courseName }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ category.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pre-fix">
                    <label
                      for="title"
                      style="
                        font-size: 16px;
                        color: #47484b;
                        font-weight: bold;
                        margin-top: 5px;
                      "
                      >{{ $t("title") }}</label
                    >
                    <v-select
                      v-model="title"
                      :items="Titleitems"
                      hide-no-data
                      hide-details
                      solo
                      dense
                      item-text="title"
                      item-value="titleID"
                      clearable
                      placeholder="-- Please select --"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="SelectAllTitle2">
                          <v-list-item-action>
                            <v-icon :color="title.length > 0 ? '#a6cc39' : ''">
                              {{ Titleicon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.title }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text text-caption"
                          >(+{{ title.length - 1 }} others)</span
                        >
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    xl="4"
                    lg="4"
                    style="padding-top: 2.2rem"
                  >
                    <v-btn
                      text
                      class="text-capitalize"
                      height="40"
                      style="
                        border: 1px solid #c0c0c0;
                        border-radius: 8px;
                        color: #424242;
                        font-size: 16px;
                      "
                      @click="clear()"
                      >{{ $t("clearall") }}</v-btn
                    >
                    <v-btn
                      width="120"
                      height="40"
                      class="text-capitalize btn_hover_effect ml-4"
                      style="
                        color: #ffff;
                        border: 1px solid #a6cc39;
                        border-radius: 8px;
                        font-size: 16px;
                      "
                      color="#A6CC39"
                      @click="Search()"
                      >{{ $t("search") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-tabs-items v-model="tabs" class="mt-4">
            <v-tab-item value="tab-1">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="itemsList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 14px"
                    class="text-capitalize"
                    @click="Delete(item.no)"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
              <v-row justify="space-between" class="mx-3 my-4">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="4"
                    class="d-flex justify-end"
                  >
                    <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          width="70"
                          v-bind="attrs"
                          v-on="on"
                          style="border: 1px solid #c0c0c0; opacity: 1"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="itemsPerPage = item.title"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :loading="loading"
                :items="itemsList"
                loading-text="Loading... Please wait"
                :headers="soundheaders"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 14px"
                    class="text-capitalize"
                    @click="Delete(item.no)"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
              <v-row justify="space-between" class="mx-3 my-4">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="4"
                    class="d-flex justify-end"
                  >
                    <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          width="70"
                          v-bind="attrs"
                          v-on="on"
                          style="border: 1px solid #c0c0c0; opacity: 1"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="itemsPerPage = item.title"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-3">
              <v-data-table
                :loading="loading"
                :items="itemsList"
                loading-text="Loading... Please wait"
                :headers="soundheaders"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 14px"
                    class="text-capitalize"
                    @click="Delete(item.no)"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
              <v-row justify="space-between" class="mx-3 my-4">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="4"
                    class="d-flex justify-end"
                  >
                    <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          width="70"
                          v-bind="attrs"
                          v-on="on"
                          style="border: 1px solid #c0c0c0; opacity: 1"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="itemsPerPage = item.title"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-4">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="llheaders"
                :items="itemsList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 14px"
                    class="text-capitalize"
                    @click="Delete(item.no)"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
              <v-row justify="space-between" class="mx-3 my-4">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="4"
                    class="d-flex justify-end"
                  >
                    <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          width="70"
                          v-bind="attrs"
                          v-on="on"
                          style="border: 1px solid #c0c0c0; opacity: 1"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="itemsPerPage = item.title"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-5">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="assignmentheaders"
                :items="itemsList"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 14px"
                    class="text-capitalize"
                    @click="Delete(item.no)"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
              <v-row justify="space-between" class="mx-3 my-4">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="4"
                    class="d-flex justify-end"
                  >
                    <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          width="70"
                          v-bind="attrs"
                          v-on="on"
                          style="border: 1px solid #c0c0c0; opacity: 1"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="itemsPerPage = item.title"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-6">
              <div style="display: flex; align-items: center; gap: 16px;">
            <p style="font-weight: bold; font-size: 18px; color: #47484b; margin: 0;">
              Send account detail to e-mail
            </p>
            <div>
              <v-radio-group v-model="selectedUserRadio">
              <v-row class="ma-0 pa-0" no-gutters>
                <v-col cols="auto" class="d-flex align-center">
                  <!-- Flex container for radio and image -->
                  <div style="display: flex; align-items: center;">
                    <v-radio value="thai" class="mr-2 mt-2"></v-radio>
                    <v-img
                      src="@/assets/thailandflag.svg"
                      width="35"
                      height="22"
                      contain
                    ></v-img>
                  </div>
                </v-col>

                <v-col cols="auto" class="d-flex align-center ml-2">
                  <!-- Flex container for radio and image -->
                  <div style="display: flex; align-items: center;">
                    <v-radio value="eng" class="mr-2 mt-2"></v-radio>
                   
                      <v-img
                        src="@/assets/ukflag.svg"
                        width="35"
                        height="22"
                        contain
                      ></v-img>
                   
                  </div>
                </v-col>
              </v-row>
            </v-radio-group>
            </div>
          </div>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  @click="SelectedUser()"
                  class="text-capitalize btnStyle mr-8 btn_hover_effect"
                  style="border-radius: 8px"
                  color="#A6CC39"
                  width="215"
                  >{{ $t("selecteduser") }}</v-btn
                >
              </v-row>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="userheaders"
                :items="usertablist"
                :search="search"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="pt-6"
              >
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    color="#FF6060"
                    text
                    style="font-size: 14px"
                    class="text-capitalize"
                    @click="Delete(item.no)"
                  >
                    <v-icon left>mdi-delete-outline</v-icon>
                    {{ $t("delete") }}
                  </v-btn>
                </template>
              </v-data-table>
              <v-row justify="space-between" class="mx-3 my-4">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-pagination
                      circle
                      v-model="page"
                      class="pagination"
                      :length="pageCount"
                    ></v-pagination>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="2"
                    md="3"
                    sm="4"
                    class="d-flex justify-end"
                  >
                    <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          width="70"
                          v-bind="attrs"
                          v-on="on"
                          style="border: 1px solid #c0c0c0; opacity: 1"
                          class="text-capitalize mt-1"
                          outlined
                        >
                          {{ itemsPerPage }}
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in paginationCount"
                          :key="index"
                          @click="itemsPerPage = item.title"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-row v-if="this.$store.getters.tabs != 'tab-6'">
            <v-col cols="12" md="10" sm="10" lg="10">
              <v-row class="mt-2">
                <v-col class="pl-6">
                  <v-row>
                    <v-col cols="2" class="pr-0">
                      <p class="mb-0" style="font-size: 18px; color: #424242">
                        {{ $t("Type") }}:
                      </p>
                    </v-col>
                    <v-col class="pl-0">
                      <p class="mb-0" style="font-size: 18px; color: #a6cc39">
                        {{ $t("sendnotification") }}
                      </p>
                      <v-checkbox
                        class="mt-0"
                        v-model="inbox"
                        :label="$t('inbox')"
                        color="#A6CC39"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="pushnoti"
                        color="#A6CC39"
                        :label="$t('pushnotification')"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        class=""
                        v-model="line"
                        :label="$t('line')"
                        color="#A6CC39"
                        hide-details
                      ></v-checkbox>
                     <v-row>
                        <v-col cols="2">
                      <v-checkbox
                        v-model="email"
                        color="#A6CC39"
                        :label="$t('email')"
                        hide-details
                         @change="handleCheckboxChange"
                      ></v-checkbox>
                    </v-col>
                    <v-col>
                      <template>
                      <v-col class="d-flex justify-left align-left ml-5" cols="10">
                        <v-radio-group v-model="selectedRadio"  class="radio-group"
                        :disabled="!email">
                          <v-row rows="12" class="mb-5 ml-1">
                          
                            <div class="radio-wrapper">
                             
                             <div v-if="!email" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                                value="thai"
                                class="radio-button"
                              >
                            </v-radio>
                          </div>
                            <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                          
                         
                            <div class="radio-wrapper ml-2">
                             
                             <div v-if="!email" class="radio-placeholder"> </div>
                            <v-radio
                            v-else
                            class=" radio-button"
                             value="eng"
                            
                              >
                            </v-radio>
                            </div>
                            
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              class="ml-2"
                            ></v-img>
                          </v-row>
                          </v-radio-group>
                      </v-col>
                    </template>
                    </v-col>
                  </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- first v-if -->
                <v-col v-if="this.$store.getters.tabs != 'tab-5'">
                  <v-row>
                    <v-col cols="3" class="pr-0">
                      <p class="mb-0" style="font-size: 18px; color: #424242">
                        {{ $t("usertype") }}:
                      </p>
                    </v-col>
                    <v-col class="pl-0">
                      <p class="mb-0" style="font-size: 18px; color: #a6cc39">
                        {{ $t("viewalluser") }}
                      </p>
                      <v-checkbox
                        class="mt-0"
                        v-model="all"
                        :label="$t('all')"
                        color="#A6CC39"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-if="
                          this.$store.getters.tabs != 'tab-1' &&
                          this.$store.getters.tabs != 'tab-4'
                        "
                        v-model="notfinished"
                        color="#A6CC39"
                        :label="$t('NotFinished')"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="notstarted"
                        color="#A6CC39"
                        :label="$t('NotStarted')"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="this.$store.getters.tabs != 'tab-5'">
                  <v-row>
                    <v-col cols="3" class="pr-0">
                      <p class="mb-0" style="font-size: 18px; color: #424242">
                        {{ $t("usertype") }}:
                      </p>
                    </v-col>
                    <v-col class="pl-0">
                      <p class="mb-0" style="font-size: 18px; color: #a6cc39">
                        {{ $t("requesteduser") }}
                      </p>
                      <v-checkbox
                        class="mt-0"
                        v-model="requestall"
                        :label="$t('all')"
                        color="#A6CC39"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-if="
                          this.$store.getters.tabs != 'tab-1' &&
                          this.$store.getters.tabs != 'tab-4'
                        "
                        v-model="requestnotfinished"
                        color="#A6CC39"
                        :label="$t('NotFinished')"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="requestnotstarted"
                        color="#A6CC39"
                        :label="$t('NotStarted')"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- first v-else -->
                <v-col v-else>
                  <v-row>
                    <v-col cols="3" class="pr-0">
                      <p class="mb-0" style="font-size: 18px; color: #424242">
                        {{ $t("usertype") }}:
                      </p>
                    </v-col>
                    <v-col class="pl-0">
                      <p class="mb-0" style="font-size: 18px; color: #a6cc39">
                        {{ $t("assigneduser") }}
                      </p>
                      <v-checkbox
                        class="mt-0"
                        v-model="requestall"
                        :label="$t('all')"
                        color="#A6CC39"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="requestnotfinished"
                        color="#A6CC39"
                        :label="$t('NotFinished')"
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="requestnotstarted"
                        color="#A6CC39"
                        :label="$t('NotStarted')"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
         
          <v-row class="mt-10 mb-1">
            <v-col style="text-align: right">
              <v-btn
                width="100"
                class="text-capitalize btnStyle btn_hover_effect"
                style="border-radius: 8px"
                color="#A6CC39"
                @click="submitSentNoti()"
                >{{ $t("submit") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-dialog width="500" v-model="Dialog" scrollable persistent>
      <v-card>
        <v-card-title>
          <v-tabs hide-slider v-model="dialogtabs" class="pt-3">
            <v-tab
              href="#tab-1"
              class="font-style text-capitalize userTab"
              style="
                width: 165px;
                border-radius: 10px 0px 0px 10px;
                border: 1px solid #9fc437;
              "
              >{{ $t("user") }}</v-tab
            >
            <v-tab
              href="#tab-2"
              style="
                width: 165px;
                border-radius: 0px 10px 10px 0px;
                border: 1px solid #9fc437;
              "
              class="font-style text-capitalize userTab"
              >{{ $t("group") }}</v-tab
            >
          </v-tabs>
        </v-card-title>
        <div class="mt-p pt-3 ml-3 mr-8">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                placeholder="search..."
                v-model="searchUser"
                class="custom-padding mb-4 ml-3 mr-3"
              >
              </v-text-field>
            </div>
        <v-card-text style="height: 500px">
          <v-tabs-items v-model="dialogtabs" class="mt-5">
            <v-tab-item value="tab-1">
              <v-data-table
                :items="filteredUsers"
                item-key="empID"
                :loading="userLoading"
                @toggle-select-all="SelectAllUser"
                loading-text="Loading... Please wait"
                v-model="userSelected"
                show-select
                :headers="userListHeader"
                hide-default-footer
                @page-count="dialogpageCount = $event"
                :page.sync="dialogpage"
              ></v-data-table>
              <v-row justify="end">
                <div class="ma-5 d-flex">
                  <v-pagination
                    circle
                    v-model="dialogpage"
                    class="pagination"
                    :length="dialogpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-data-table
                :items="filteredDepartments"
                @toggle-select-all="SelectAllDept"
                item-key="groupID"
                :loading="departmentLoading"
                loading-text="Loading... Please wait"
                v-model="departmentSelected"
                show-select
                :headers="departmentListHeader"
                hide-default-footer
                @page-count="dialogpageCount = $event"
                :page.sync="dialogpage"
              ></v-data-table>
              <v-row justify="end">
                <div class="ma-5 d-flex">
                  <v-pagination
                    circle
                    v-model="dialogpage"
                    class="pagination"
                    :length="dialogpageCount"
                  ></v-pagination>
                </div>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="
              (Dialog = false),
                (userSelected = []),
                ((departmentSelected = []), (this.dialogtabs = 'tab-1'))
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-capitalize font-style btn_hover_effect"
            color="#A6CC39"
            style="border-radius: 8px"
            @click="saveselectedList()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletedialog" width="587">
      <v-card>
        <v-card-title
          style="font-size: 30px"
          class="secondary white--text justify-center"
          >{{ $t("deleteconfirm") }}</v-card-title
        >

        <v-card-text>
          <p
            class="mt-5"
            style="
              font-size: 16px;
              color: #4d4f5c;
              opacity: 1;
              text-align: center;
            "
          >
            {{ $t("suredelete") }} " #{{ deleteid }}" ?
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="font-size: 20px"
            width="100"
            text
            @click="deletedialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 20px"
            color="error"
            @click="deleteComfirm()"
            >{{ $t("confirm") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingShow" persistent width="300">
      <v-card color="#1976D2" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#FAA91B"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    searchUser: "",
    selectedUserRadio:"thai",
    selectedRadio: "null",
    permissionDialog: false,
    permissionMessage: "",
    showFilterTools: false,
    item_No: 0,
    breadcrumbs: [
      {
        // image: "bell-ring.png",
        sidebar_tilte: "sendnotification",
      },
      {
        text: "article", //sendnotification
      },
    ],
    loadingShow: false,
    sectionUserOrDepart: "",
    tabValue: "tab-1",
    frommenu: false,
    tomenu: false,
    endmenu: false,
    endmenuto: false,
    expireddatemenu: false,
    expireddatemenuto: false,
    startdatefrom: "",
    startdateto: "",
    enddatefrom: "",
    enddateto: "",
    expireddatefrom: "",
    expireddateto: "",
    Categoryitems: [],
    Titleitems: [],
    title: [],
    category: [],
    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    inbox: false,
    pushnoti: false,
    email: false,
    line: false,
    all: false,
    notfinished: false,
    notstarted: false,
    requestall: false,
    requestnotfinished: false,
    requestnotstarted: false,
    Dialog: false,
    tempTitle: [],
    dialogtabs: "tab-1",
    users: [],
    departments: [],
    userLoading: true,
    departmentLoading: true,
    userSelected: [],
    departmentSelected: [],
    dialogpageCount: 0,
    dialogpage: 1,
    itemsList: [
      // {
      //   no: 1,
      //   category: "Marketing",
      //   title: "Digital Marketing",
      //   file: "Music1.mp3",
      //   video: "Video1.mp4",
      //   viewperiod: "01/06/2022-21/06/2022",
      // },
      // {
      //   no: 2,
      //   category: "SEO",
      //   title: "Indexing",
      //   file: "Music2.mp3",
      //   video: "Video2.mp4",
      //   viewperiod: "01/06/2022-21/06/2022",
      // },
      // {
      //   no: 3,
      //   category: "SEO",
      //   title: "Services",
      //   file: "Music3.mp3",
      //   video: "Clip1.mp4",
      //   viewperiod: "01/06/2022-21/06/2022",
      // },
    ],
    usertablist: [],
    search: "",
    deletedialog: false,
    deleteid: 0,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
  }),
  computed: {
    filteredUsers() {
      if (this.dialogtabs === 'tab-1' && this.searchUser) {
        return this.users.filter((user) =>
          user.employeeName.toLowerCase().includes(this.searchUser.toLowerCase())
        );
      } else {
        return this.users;
      }
    },
    filteredDepartments() {
      if (this.dialogtabs === 'tab-2' && this.searchUser) {
        return this.departments.filter((department) =>
          department.groupName.toLowerCase().includes(this.searchUser.toLowerCase())
        );
      } else {
        return this.departments;
      }
    },
    tabs: {
      get() {
        switch (this.$store.getters.tabs) {
          case "tab-1":
            this.GetCategoryItems();
            this.GetArticleTitle();
            //this.GetArticleList(1)
            break;
          case "tab-2":
            this.GetCategoryItems();
            this.GetSoundTitle();
            //this.GetSoundList(1)
            break;
          case "tab-3":
            this.GetCategoryItems();
            this.GetVideoTitle();
            break;
          case "tab-4":
            this.GetLessonLearnTitle();
            break;
          case "tab-5":
            this.GetCourseItems();
            this.GetTitleItems();
            break;
          case "tab-6":
            break;
        }
        return this.$store.getters.tabs;
      },
      set() {
        this.$store.commit("changeTab", this.tabValue);
      },
    },
    selectAll() {
      return this.category.length === this.Categoryitems.length;
    },
    selectAllTitle() {
      return this.title.length === this.Titleitems.length;
    },
    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Titleicon() {
      if (this.selectAllTitle) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    itemsWithIndex() {
      return this.itemsList.map((itemsList, index) => ({
        ...itemsList,
        index: index + 1,
      }));
    },
    headers() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          align: "left",
        },
        {
          text: this.$t("category"),
          value: "topicName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
        },
        {
          text: this.$t("action"),
          value: "actions",
        },
      ];
    },
    soundheaders() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          align: "left",
        },
        {
          text: this.$t("category"),
          value: "topicName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("filenameL"),
          value: "soundName",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
        },
        {
          text: this.$t("action"),
          value: "actions",
        },
      ];
    },
    llheaders() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
        },
        {
          text: this.$t("action"),
          value: "actions",
        },
      ];
    },
    assignmentheaders() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          align: "left",
        },
        {
          text: this.$t("course"),
          value: "courseName",
          align: "left",
        },
        {
          text: this.$t("title"),
          value: "titleName",
          align: "left",
        },
        {
          text: this.$t("videotitle"),
          value: "videoName",
          align: "left",
        },
        {
          text: this.$t("viewperiod"),
          value: "periodDate",
          align: "left",
        },
        {
          text: this.$t("action"),
          value: "actions",
        },
      ];
    },
    userheaders() {
      return [
        {
          text: this.$t("no"),
          value: "no",
          align: "left",
        },
        {
          text: this.$t("name"),
          value: "name",
          align: "left",
        },
        {
          text: this.$t("group"),
          value: "group",
          align: "left",
        },
        {
          text: this.$t("email"),
          value: "email",
          align: "left",
        },
        {
          text: this.$t("action"),
          value: "actions",
        },
      ];
    },
    userListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "fullName",
        },
      ];
    },
    departmentListHeader() {
      return [
        {
          text: this.$t("name"),
          align: "left",
          value: "groupName",
        },
      ];
    },
  },
  mounted() {
    this.$store.commit("changeTab", "tab-1");
    this.GetCategoryItems();
    this.GetArticleTitle();
  },
  watch: {
    userSelected(val) {
      if (val.length != 0) {
        this.departmentSelected = [];
      }
    },
    departmentSelected(val) {
      if (val.length != 0) {
        this.userSelected = [];
      }
    },
  },
  methods: {
    handleCheckboxChange() {
      console.log("hello",this.email);
      if (this.email) {
        
        this.selectedRadio = 'thai';
        console.log("hello",this.selectedRadio);
      } else {
        this.selectedRadio = null;
        console.log("hello", this.selectedRadio);
      }
    },
    StartDateFromInput() {
      this.frommenu = false;
      if (this.startdateto == "") {
        this.startdateto = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.startdatefrom > this.startdateto) {
        this.startdateto = this.startdatefrom;
      }
    },
    StartDateToInput() {
      this.tomenu = false;
      if (this.startdatefrom == "") {
        this.startdatefrom = this.startdateto;
      }
    },
    EndDateFromInput() {
      this.endmenu = false;
      if (this.enddateto == "") {
        this.enddateto = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.enddatefrom > this.enddateto) {
        this.enddateto = this.enddatefrom;
      }
    },
    EndDateToInput() {
      this.endmenuto = false;
      if (this.enddatefrom == "") {
        this.enddatefrom = this.enddateto;
      }
    },
    ExpireDateFromInput() {
      this.expireddatemenu = false;
      if (this.expireddateto == "") {
        this.expireddateto = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      if (this.expireddatefrom > this.expireddateto) {
        this.expireddateto = this.expireddatefrom;
      }
    },
    ExpireDateToInput() {
      this.expireddatemenuto = false;
      if (this.expireddatefrom == "") {
        this.expireddatefrom = this.expireddateto;
      }
    },
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    // allowedDates(val){
    //   return val >= new Date().toISOString().substr(0, 10);
    // },
    allowedEndDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    allowedDatesTo(val) {
      return (
        val <= new Date().toISOString().substr(0, 10) &&
        val >= this.startdatefrom
      );
    },
    allowedEndDatesTo(val) {
      return (
        val >= new Date().toISOString().substr(0, 10) && val >= this.enddatefrom
      );
    },
    allowedExpDate(val) {
      return val >= this.expireddatefrom;
    },
    getSelectAll() {
      if (this.category.length == 0) {
        this.category = this.Categoryitems.map(({ id }) => id);
      } else {
        this.category = [];
      }
    },
    getSelectAll2() {
      if (this.category.length == 0) {
        this.category = this.Categoryitems.map(({ courseID }) => courseID);
      } else {
        this.category = [];
      }
    },
    SelectAllTitle() {
      if (this.title.length == 0) {
        this.title = this.Titleitems.map(({ id }) => id);
      } else {
        this.title = [];
      }
    },
    SelectAllTitle2() {
      if (this.title.length == 0) {
        this.title = this.Titleitems.map(({ titleID }) => titleID);
      } else {
        this.title = [];
      }
    },
    SelectAllUser() {
      if (this.userSelected == undefined || this.userSelected.length == 0) {
        this.userSelected = this.users;
      } else {
        this.userSelected = [];
      }
    },
    SelectAllDept() {
      if (
        this.departmentSelected == undefined ||
        this.departmentSelected.length == 0
      ) {
        this.departmentSelected = this.departments;
      } else {
        this.departmentSelected = [];
      }
    },
    SelectCategory(event) {
      this.Titleitems = [];
      if (event.length > 0) {
        for (let i of event) {
          const found = this.tempTitle.filter((c) => c.topicID == i);
          if (found.length != 0) {
            if (this.Titleitems.length == 0) {
              this.Titleitems = found;
            } else {
              for (let title of found) {
                this.Titleitems.push(title);
              }
            }
          }
        }
      } else {
        this.Titleitems = this.tempTitle;
      }
    },
    SelectCourse(event) {
      this.Titleitems = [];
      if (event.length > 0) {
        for (let i of event) {
          const found = this.tempTitle.filter((c) => c.courseID == i);
          if (found.length != 0) {
            if (this.Titleitems.length == 0) {
              this.Titleitems = found;
            } else {
              for (let title of found) {
                this.Titleitems.push(title);
              }
            }
          }
        }
      } else {
        this.Titleitems = this.tempTitle;
      }
    },
    Search() {
      if (
        (this.title.length == 0) &
        (this.category.length == 0) &
        (this.startdatefrom == "") &
        (this.startdateto == "") &
        (this.enddatefrom == "") &
        (this.enddateto == "") &
        (this.expireddatefrom == "") &
        (this.expireddateto == "")
      ) {
        alert("Please select condition to search!");
      } else {
        if (this.$store.getters.tabs == "tab-1") {
          this.GetArticleList(2);
        } else if (this.$store.getters.tabs == "tab-2") {
          this.GetSoundList(2);
        } else if (this.$store.getters.tabs == "tab-3") {
          this.GetVideoList(2);
        } else if (this.$store.getters.tabs == "tab-4") {
          this.GetLLList(2);
        } else if (this.$store.getters.tabs == "tab-5") {
          this.GetAssignmentCourse(2);
        }
      }
    },
    Delete(id) {
      this.deletedialog = true;
      this.deleteid = id;
    },
    validate() {
      this.validatedialog = true;
    },
    deleteComfirm() {
      if (this.$store.getters.tabs === "tab-6") {
        this.usertablist.splice(
          this.usertablist.findIndex((item) => item.no === this.deleteid),
          1
        );
      } else if (
        this.$store.getters.tabs === "tab-1" ||
        this.$store.getters.tabs === "tab-2" ||
        this.$store.getters.tabs === "tab-3" ||
        this.$store.getters.tabs === "tab-4" ||
        this.$store.getters.tabs === "tab-5"
      ) {
        this.itemsList.splice(
          this.itemsList.findIndex((item) => item.no === this.deleteid),
          1
        );
      } else {
        // Default case: Uncomment and modify this section if needed.
        // this.itemsList.splice(
        //   this.itemsList.findIndex((item) => item.index === this.deleteid)
        // );
      }

      this.deletedialog = false;
    },
    SelectedUser() {
      this.Dialog = true;
      this.getUserData();
      this.getDepartment();
    },
    saveselectedList() {
      this.Dialog = false;
      let selectedlist = [];
      if (this.userSelected.length > 0) {
        this.sectionUserOrDepart = "user";
        selectedlist = this.userSelected.map((v, i) => ({
          ...v,
          no: i + 1,
          name: v.fullName,
          group: v.department,
          email: v.email,
        }));
      } else {
        this.sectionUserOrDepart = "department";
        selectedlist = this.departmentSelected.map((v, i) => ({
          ...v,
          no: i + 1,
          name: v.groupName,
          group: v.groupName,
          email: "",
        }));
      }
      this.usertablist = selectedlist;
    },
    async getUserData() {
      const res = await this.$axios.get(
        `${this.web_url}EmployeeEXT/GetEmployeeExtAll?id=` +
          localStorage.getItem("companyID")
      );
      console.log("userdata",res.data.data);
      this.users = res.data.data.map((v) => ({
        ...v,
      }));

      this.userLoading = false;
    },
    async getDepartment() {
      const res = await this.$axios.get(
        `${this.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      console.log("departname",res.data.data);
      this.departments = res.data.data.map((v) => ({
        ...v,
      }));
      this.departmentLoading = false;
    },
    async GetCategoryItems() {
      let that = this;
      await axios
        .get(
          `${that.web_url}Topic/GetTopicDDL?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Categoryitems = response.data.data.map((v) => ({
              ...v,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.Categoryitems.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    },
    //AC ddl
    // async GetCourseItems() {
    //   var that = this;
    //   await axios
    //     .get(
    //       `${that.web_url}Assignment/GetAssignmentCourseReportName?CompanyID=` +
    //         localStorage.getItem("companyID")
    //     )
    //     .then(function(response) {
    //       if (response.data.status == 0) {
    //         that.Categoryitems = response.data.data.map((v) => ({
    //           ...v,
    //         }));
    //       }
    //     })
    //     .catch(function(err) {
    //       throw err;
    //     });
    // },
    async GetCourseItems() {
      let that = this;
      await axios
        .get(
          `${that.web_url}Assignment/GetAssignmentCourseReportName?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Categoryitems = response.data.data.map((v) => ({
              ...v,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.Categoryitems.map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    },
    async GetArticleTitle() {
      let that = this;
      that.Titleitems = [];
      that.tempTitle = [];
      await axios
        .get(
          `${that.web_url}Contents/GetArticleDDLActiveNow?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Titleitems = response.data.data.map((v) => ({
              ...v,
            }));
            that.tempTitle = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetSoundTitle() {
      let that = this;
      that.Titleitems = [];
      that.tempTitle = [];
      axios
        .get(
          `${that.web_url}Video/GetSoundDDLActiveNow?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Titleitems = response.data.data.map((v) => ({
              ...v,
            }));
            that.tempTitle = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetVideoTitle() {
      let that = this;
      that.Titleitems = [];
      that.tempTitle = [];
      axios
        .get(
          `${that.web_url}GuidedLesson/GetVideoDDLActiveNow?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Titleitems = response.data.data.map((v) => ({
              ...v,
            }));
            that.tempTitle = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetLessonLearnTitle() {
      let that = this;
      that.Titleitems = [];
      that.tempTitle = [];
      await axios
        .get(
          `${that.web_url}CaseStudies/GetLessonLearnDDLActiveNow?id=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Titleitems = response.data.data.map((v) => ({
              ...v,
            }));
            that.tempTitle = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },

    async GetTitleItems() {
      let that = this;
      that.Titleitems = [];
      that.tempTitle = [];
      await axios
        .get(
          `${that.web_url}Assignment/GetAssignmentCourseReportTitleActiveNow?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.Titleitems = response.data.data.map((v) => ({
              ...v,
            }));
            that.tempTitle = that.Titleitems;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async GetArticleList(page) {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.category,
        titleID: page == 1 ? [] : this.title,
        startDateFrom: page == 1 ? "" : that.startdatefrom,
        startDateTo: page == 1 ? "" : that.startdateto,
        endDateFrom: page == 1 ? "" : that.enddatefrom,
        endDateTo: page == 1 ? "" : that.enddateto,
        expiredDateFrom: page == 1 ? "" : that.expireddatefrom,
        expiredDateTo: page == 1 ? "" : that.expireddateto,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}SendNotification/GetArticleList`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.itemsList = response.data.data.map((v, index) => ({
              ...v,
              no: index + 1,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetSoundList(page) {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.category,
        titleID: page == 1 ? [] : this.title,
        startDateFrom: page == 1 ? "" : that.startdatefrom,
        startDateTo: page == 1 ? "" : that.startdateto,
        endDateFrom: page == 1 ? "" : that.enddatefrom,
        endDateTo: page == 1 ? "" : that.enddateto,
        expiredDateFrom: page == 1 ? "" : that.expireddatefrom,
        expiredDateTo: page == 1 ? "" : that.expireddateto,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}SendNotification/GetSoundList`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.itemsList = response.data.data.map((v, index) => ({
              ...v,
              no: index + 1,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetVideoList(page) {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        topicID: page == 1 ? [] : this.category,
        titleID: page == 1 ? [] : this.title,
        startDateFrom: page == 1 ? "" : that.startdatefrom,
        startDateTo: page == 1 ? "" : that.startdateto,
        endDateFrom: page == 1 ? "" : that.enddatefrom,
        endDateTo: page == 1 ? "" : that.enddateto,
        expiredDateFrom: page == 1 ? "" : that.expireddatefrom,
        expiredDateTo: page == 1 ? "" : that.expireddateto,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}SendNotification/GetVideoList`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.itemsList = response.data.data.map((v, index) => ({
              ...v,
              no: index + 1,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetLLList(page) {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        titleID: page == 1 ? [] : this.title,
        startDateFrom: page == 1 ? "" : that.startdatefrom,
        startDateTo: page == 1 ? "" : that.startdateto,
        endDateFrom: page == 1 ? "" : that.enddatefrom,
        endDateTo: page == 1 ? "" : that.enddateto,
        expiredDateFrom: page == 1 ? "" : that.expireddatefrom,
        expiredDateTo: page == 1 ? "" : that.expireddateto,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(`${that.web_url}SendNotification/GetLessonLearnList`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            that.itemsList = response.data.data.map((v, index) => ({
              ...v,
              no: index + 1,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async GetAssignmentCourse(page) {
      let that = this;
      that.itemsList = [];
      that.loading = true;
      let request = {
        companyID: localStorage.getItem("companyID"),
        CourseID: page == 1 ? [] : this.category, //Course
        titleID: page == 1 ? [] : this.title,
        startDateFrom: page == 1 ? "" : that.startdatefrom,
        startDateTo: page == 1 ? "" : that.startdateto,
        endDateFrom: page == 1 ? "" : that.enddatefrom,
        endDateTo: page == 1 ? "" : that.enddateto,
        offset: that.dateoffset,
        separatePage: page,
      };
      await axios
        .post(
          `${that.web_url}SendNotification/GetAssignmentCourseList`,
          request
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.itemsList = response.data.data.map((v, index) => ({
              ...v,
              no: index + 1,
            }));
          }
        })
        .catch(function (err) {
          throw err;
        });
      that.loading = false;
    },
    async submitSentNoti() {
      let that = this;

      let checkPermission = that.check_Permissions("sendnotification");
      if (checkPermission === false) {
        that.permissionDialog = true;
        that.permissionMessage =
          "You don't have permission to submit Notification.";
        return false;
      }

      if (
        ((that.inbox === false &&
          that.pushnoti === false &&
          that.email === false &&
          that.line === false) ||
          (that.all === false &&
            that.notfinished === false &&
            that.notstarted === false &&
            that.requestall === false &&
            that.requestnotfinished === false &&
            that.requestnotstarted === false) ||
          (that.itemsList.length == 0) & (that.usertablist.length == 0)) &&
        this.$store.getters.tabs != "tab-6"
      ) {
        alert("Please select a Send Notification format and User Type!");
      } else if (
        ((that.inbox === false &&
          that.pushnoti === false &&
          that.email === false &&
          that.line === false) ||
          (that.itemsList.length == 0) & (that.usertablist.length == 0)) &&
        this.$store.getters.tabs != "tab-6"
      ) {
        alert("Please select a Send Notification format!");
      } else if (
        ((that.all === false &&
          that.notfinished === false &&
          that.notstarted === false &&
          that.requestall === false &&
          that.requestnotfinished === false &&
          that.requestnotstarted === false) ||
          (that.itemsList.length == 0) & (that.usertablist.length == 0)) &&
        this.$store.getters.tabs != "tab-6"
      ) {
        alert("Please select the User type you wish to send notifications!");
      } else {
        that.loading = true;
        that.loadingShow = true;

        let request;
        let userID = [];
        let groupID = [];

        if (this.$store.getters.tabs == "tab-1") {
          let ArticleList = [];

          that.itemsList.forEach((element) => {
            ArticleList.push({
              TitleID: element.titleID,
              TitleName: element.titleName,
              CategoryName: element.topicName,
            });
          });

          request = {
            companyID: localStorage.getItem("companyID"),
            ArticleSoundVideoList: ArticleList,

            Inbox: that.inbox,
            PushNotification: that.pushnoti,
            Email: that.email,
            emailLanguage: this.selectedRadio,
            Line: that.line,

            UserAll: that.all,
            UserNotFinished: that.notfinished,
            UserNotStarted: that.notstarted,

            AssignedUserAll: that.requestall,
            AssignedUserNotFinished: that.requestnotfinished,
            AssignedUserNotStarted: that.requestnotstarted,
          };
          console.log("emaildata",request);
          await axios
            .post(
              `${that.web_url}SendNotification/SentNotiFromUserArticle`,
              request
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.clear();
                alert("Send complete.");
              }
            })
            .catch(function (err) {
              that.loadingShow = false;
              throw err;
            });
        } else if (this.$store.getters.tabs == "tab-2") {
          let SoundList = [];

          that.itemsList.forEach((element) => {
            SoundList.push({
              TitleID: element.titleID,
              VideoID: element.videoID,
              TitleName: element.titleName,
              CategoryName: element.topicName,
            });
          });

          request = {
            companyID: localStorage.getItem("companyID"),
            ArticleSoundVideoList: SoundList,

            Inbox: that.inbox,
            PushNotification: that.pushnoti,
            Email: that.email,
            emailLanguage: this.selectedRadio,
            Line: that.line,

            UserAll: that.all,
            UserNotFinished: that.notfinished,
            UserNotStarted: that.notstarted,

            AssignedUserAll: that.requestall,
            AssignedUserNotFinished: that.requestnotfinished,
            AssignedUserNotStarted: that.requestnotstarted,
          };
          await axios
            .post(
              `${that.web_url}SendNotification/SentNotiFromUserSound`,
              request
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.clear();
                alert("Send complete.");
              }
            })
            .catch(function (err) {
              that.loadingShow = false;
              throw err;
            });
        } else if (this.$store.getters.tabs == "tab-3") {
          let VideoList = [];

          that.itemsList.forEach((element) => {
            VideoList.push({
              TitleID: element.titleID,
              VideoID: element.videoID,
              TitleName: element.titleName,
              CategoryName: element.topicName,
            });
          });

          request = {
            companyID: localStorage.getItem("companyID"),
            ArticleSoundVideoList: VideoList,

            Inbox: that.inbox,
            PushNotification: that.pushnoti,
            Email: that.email,
            emailLanguage: this.selectedRadio,
            Line: that.line,

            UserAll: that.all,
            UserNotFinished: that.notfinished,
            UserNotStarted: that.notstarted,

            AssignedUserAll: that.requestall,
            AssignedUserNotFinished: that.requestnotfinished,
            AssignedUserNotStarted: that.requestnotstarted,
          };
          await axios
            .post(
              `${that.web_url}SendNotification/SentNotiFromUserVideo`,
              request
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.clear();
                alert("Send complete.");
              }
            })
            .catch(function (err) {
              that.loadingShow = false;
              throw err;
            });
        } else if (this.$store.getters.tabs == "tab-4") {
          let LessonLearnList = [];

          that.itemsList.forEach((element) => {
            LessonLearnList.push({
              TitleID: element.titleID,
              TitleName: element.titleName,
            });
          });

          request = {
            companyID: localStorage.getItem("companyID"),
            ArticleSoundVideoList: LessonLearnList,

            Inbox: that.inbox,
            PushNotification: that.pushnoti,
            Email: that.email,
            emailLanguage: this.selectedRadio,
            Line: that.line,

            UserAll: that.all,
            UserNotFinished: that.notfinished,
            UserNotStarted: that.notstarted,

            AssignedUserAll: that.requestall,
            AssignedUserNotFinished: that.requestnotfinished,
            AssignedUserNotStarted: that.requestnotstarted,
          };
          await axios
            .post(
              `${that.web_url}SendNotification/SentNotiFromUserLessonLearn`,
              request
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.clear();
                alert("Send complete.");
              }
            })
            .catch(function (err) {
              that.loadingShow = false;
              throw err;
            });
        } else if (this.$store.getters.tabs == "tab-5") {
          let AssignedCourseList = [];

          that.itemsList.forEach((element) => {
            AssignedCourseList.push({
              CourseID: element.courseID,
              TitleID: element.titleID,
            });
          });

          request = {
            companyID: localStorage.getItem("companyID"),
            AssignedCourseList: AssignedCourseList,

            Inbox: that.inbox,
            PushNotification: that.pushnoti,
            Email: that.email,
            emailLanguage: this.selectedRadio,
            Line: that.line,

            AssignedUserAll: that.requestall,
            AssignedUserNotFinished: that.requestnotfinished,
            AssignedUserNotStarted: that.requestnotstarted,
          };
          await axios
            .post(
              `${that.web_url}SendNotification/SentNotiAssignmentCourse`,
              request
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.clear();
                alert("Send complete.");
              }
            })
            .catch(function (err) {
              that.loadingShow = false;
              throw err;
            });
        } else if (this.$store.getters.tabs == "tab-6") {
          that.usertablist.forEach((element) => {
            if (that.sectionUserOrDepart == "user") {
              userID.push(element.userID);
            } else {
              groupID.push(element.groupID);
            }
          });

          request = {
            companyID: localStorage.getItem("companyID"),
            userID: userID,
            emailLanguage: this.selectedUserRadio,
            groupID: groupID,
          };
          console.log("userdata",request);
          await axios
            .post(
              `${that.web_url}SendNotification/SentEmailFromUserOrGroup`,
              request
            )
            .then(function (response) {
              if (response.data.status == 0) {
                that.clear();
                alert("Send complete.");
              }
            })
            .catch(function (err) {
              that.loadingShow = false;
              throw err;
            });
        }

        that.loading = false;
        that.loadingShow = false;
      }
    },
    clear() {
      this.showFilterTools = false;
      this.inbox = false;
      this.pushnoti = false;
      this.email = false;
      this.line = false;
      this.all = false;
      this.notfinished = false;
      this.notstarted = false;
      this.requestall = false;
      this.requestnotfinished = false;
      this.requestnotstarted = false;
      this.itemsList = [];
      this.usertablist = [];
      this.title = [];
      this.category = [];
      this.startdatefrom = "";
      this.startdateto = "";
      this.enddatefrom = "";
      this.enddateto = "";
      this.expireddatefrom = "";
      this.expireddateto = "";
    },
    changeTabTitle(tabtitle) {
      this.breadcrumbs = [
        {
          sidebar_tilte: "sendnotification",
        },
        {
          text: tabtitle,
        },
      ];
    },
  },
};
</script>
<style scoped>
.radio-group {
  display: flex;
  align-items: center;
}

.radio-wrapper {
  position: relative;
  width: 22px;
  height: 22px;
}

.radio-placeholder,
.radio-button {
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.radio-placeholder {
  background-color:#424242;
  opacity: 0.1;
  pointer-events: none; /* Prevent interaction */
}
::v-deep .pre-fix .v-input__slot {
  border: 1px solid #c0c0c0;
  font-size: 16px !important;
  /* width: 200px !important; */
  border-radius: 8px !important;
  box-shadow: unset !important;
}
/* >>> .v-input__control {
  align-content: end;
} For date ui..*/
::v-deep .v-input__slot {
  /* width: 200px; */
  border-radius: 8px !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
.margintop {
  margin-top: -25px;
}
::v-deep .v-list-item__content {
  color: #4d4f5c;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
.font-style {
  font-size: 16px;
  color: #4d4f5c;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}
::v-deep .btnStyle .v-btn__content {
  font-size: 16px;
  color: #fff;
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .userTab.v-tab--active {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
}
::v-deep .v-tab--active {
  background-color: #ffffff !important;
  color: #a6cc39 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #424242 !important;
}
::v-deep .v-simple-checkbox .v-icon {
  color: #a6cc39 !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 300px;
    overflow: auto;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .tab-mobileres {
    width: 500px;
    overflow: auto;
  }
}
</style>

<style scoped>
::v-deep .v-divider--inset:not(.v-divider--vertical) {
  max-width: 10px;
  margin-top: 3.5rem;
  margin-left: 0px;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: unset;
  }
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
  padding-left: 45px;
}
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
::v-deep .v-image__image--cover {
  background-size: contain;
}
</style>
